import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Section from "components/section"
import Img from "gatsby-image"
import style from "./index.module.scss"

const Atf = ({ heading }) => {

  const data = useStaticQuery(graphql`
    query AtfQuery {
      pattern: file(relativePath: {eq: "pattern.png"}) {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `
  );

  return (
    <>
      <Section hasMargin={false} className={style.atf}>
        <h1 className={style.heading}>
          {heading}
        </h1>
      </Section>
      <Img
        className={style.pattern}
        fluid={ data.pattern.childImageSharp.fluid }
      />
    </>
  )
}

Atf.propTypes = {
  heading: PropTypes.string.isRequired,
}

export default Atf
