import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import classNames from "classnames"
import style from "./index.module.scss"

const Pattern = ({ position, size, offset, color, mirror }) => {

  const data = useStaticQuery(graphql`
    query PatternQuery {
      patternYellow: file(relativePath: {eq: "pattern.png"}) {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      patternGrey: file(relativePath: {eq: "pattern-grey.png"}) {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
  )

  const patternClasses = classNames(
    [style.pattern],
    {[style.left]: position === "left"},
    {[style.right]: position === "right"},
    {[style.sm]: size === "sm"},
    {[style.md]: size === "md"},
    {[style.lg]: size === "lg"},
    {[style.xl]: size === "xl"},
    {[style.offsetSm]: offset === "sm"},
    {[style.offsetMd]: offset === "md"},
    {[style.offsetLg]: offset === "lg"},
    {[style.offsetXl]: offset === "xl"},
    {[style.mirror]: mirror},
  );

  const pattern = color === "yellow" ? data.patternYellow : data.patternGrey;

  return (
    <Img
      className={patternClasses}
      fluid={pattern.childImageSharp.fluid}
    />
  )
}

Pattern.propTypes = {
  position: PropTypes.string,
  size: PropTypes.string,
  offset: PropTypes.string,
  color: PropTypes.string,
  mirror: PropTypes.bool,
}

Pattern.defaultProps = {
  position: "left",
  offset: "sm",
  size: "sm",
  color: "yellow",
  mirror: false,
}

export default Pattern
