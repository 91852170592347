import React, { useState } from "react"
import ButtonBase from "@material-ui/core/ButtonBase"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import classNames from "classnames"
import { RichText } from "prismic-reactjs"
import style from "./index.module.scss"

const Collapse = ({ heading, text }) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const cardClasses = classNames(
    [style.card],
    {[style.cardOpen]: isOpen},
  );

  const labelClasses = classNames(
    "typeBold",
    [style.label],
  );

  return (
    <div className={style.collapse}>
      <ButtonBase
        className={style.collapseButton}
        onClick={() => toggleCollapse()}
      >
        <span className={labelClasses}>
          {heading}
        </span>
        <ExpandMoreIcon fontSize="small"/>
      </ButtonBase>
      <div className={cardClasses}>
        <p className="typeRegular">
          <RichText
            render={text}
          />
        </p>
      </div>
    </div>
  )
}

// Collape.propTypes = {
//   heading: PropTypes.string.isRequired,
//   text:
// }

export default Collapse
