import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Section from "components/section"
import Atf from "components/atf"
import Grid from "@material-ui/core/Grid"
import Collapse from "components/collapse"
import Pattern from "components/pattern"
import SEO from "components/seo"

import style from "./rahmenbedingungen.module.scss"

const ConditionsPage = ({data}) => {

  const node = data.allPrismicRahmenbedigungen.edges[0].node.data;
  const slice_edges = data.allPrismicRahmenbedigungenBody1Collapse.edges;

  // Map Slices
  const collapse_mapped = slice_edges.map( ({node}) => (
    <Collapse
      key = {node.items[0].collpase_title.text}
      heading = {node.items[0].collpase_title.text}
      text = {node.items[0].collapse_text.raw}
    />
  ));

  return (
    <Layout>
      <SEO
        title={node.display_title.text}
        description={node.intro_text.text}
        canonical
        canonicalUri="/rahmenbedigungen/"
      />
      <Atf heading={node.display_title.text} />
      <Section
        className={style.intro}
        hasMargin={true}
        hasBackground={false}
        hasPadding={false}
      >
        <Grid
          container
          spacing={0}
          justify="center"
        >
          <Grid item xs={12} md={8}>
            <h2 className="heading text-center">
              {node.intro_heading.text}
            </h2>
            <p className="text-default">
              {node.intro_text.text}
            </p>
          </Grid>
        </Grid>
      </Section>

      <Pattern
        color="grey"
        size="sm"
        offset="sm"
        position="right"
      />

      <Section
        className={style.faq}
        hasMargin={true}
        hasBackground={true}
        hasPadding={true}
      >
        <Grid
          container
          spacing={0}
          justify="center"
        >
          <Grid item xs={12} md={8}>
            {collapse_mapped}
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query ConditionsQuery {
    allPrismicRahmenbedigungen {
      edges{
        node {
          data {
            display_title {
              text
            }
            intro_heading {
              text
            }
            intro_text {
              text
            }
          }
        }
      }
    }
    allPrismicRahmenbedigungenBody1Collapse {
      edges {
        node {
          items {
            collpase_title {
              text
            }
            collapse_text {
              raw
            }
          }
        }
      }
    }
  }
`

export default ConditionsPage
